import { config } from 'utils/axios_configuration';

// AWS
export const AWS_API_URL = 'https://api.tomati.app';
export const AWS_MOBILE_URL = 'https://mobile.tomati.app/login';

// AWS-staging
export const AWS_STAGING_API_URL = 'https://api.staging-tomati.app';
export const AWS_STAGING_MOBILE_URL = 'https://staging-mobile.tomati.app';

// VERCEL
export const HEROKU_API_URL = 'https://tomati-api.herokuapp.com';

export const LOCAL_DESKTOP_URL = 'http://localhost:3002';
export const CSV_TEMPLATE_URL = 'https://tomati.s3.af-south-1.amazonaws.com/sample-menu.csv';

export const MOBILE_APP_URL = process.env.REACT_APP_AWS === 'true'
  ? AWS_MOBILE_URL
  : process.env.REACT_APP_AWS_STAGING
    ? AWS_STAGING_MOBILE_URL
    : 'http://localhost:3002';

// Auth Routes
export const CLIENT_LOGIN = config[config.env] + '/auth/login';
export const GET_EMAIL_OTP = config[config.env] + '/verifications/email/get-code';
export const GET_LOCATIONS = config[config.env] + '/outletlocations';
export const CHECK_EMAIL_CODE = config[config.env] + '/verifications/email/check-code';
export const FORGOT_PASSWORD = config[config.env] + '/auth/reset';
export const RESET_PASSWORD = config[config.env] + '/auth/reset';
export const VERIFY_CREDENTIALS = config[config.env] + '/auth/verify-credentials';
export const GET_USER = config[config.env] + '/accounts/me';
export const UPDATE_USER = config[config.env] + '/auth/reset-profile';
export const GET_ALL_USERS = config[config.env] + '/auth/get-all-users';
export const INVITE_COLLABORATOR_EVENT = (id) => config[config.env] + `/auth/outlet-event/${id}/waiter-signup`;
export const INVITE_COLLABORATOR_VENUE = (id) => config[config.env] + `/auth/outlet-venue/${id}/waiter-signup`;
export const GET_USER_LIMIT = config[config.env] + '/payment/retrive-subscription';

//Outlets
export const GET_OUTLETS = config[config.env] + '/stores/user-venues';
export const TOGGLE_MENU = config[config.env] + '/stores';
export const UPDATE_MENU_STATUS = config[config.env] + '/stores/update_menu_status';
export const GET_OUTLET = config[config.env] + '/stores';
export const ADD_OUTLET_REQUEST = config[config.env] + '/stores';
export const ADD_OUTLET_COLLABORATOR = config[config.env] + '/auth/invite-collaborator';

//Events
export const GET_EVENTS = config[config.env] + '/outletevents/user-events';
export const GET_EVENT = config[config.env] + '/outletevents';
export const ADD_EVENT_REQUEST = config[config.env] + '/outletevents';
export const ADD_EVENT_COLLABORATOR = config[config.env] + '/auth/invite-collaborator';
