import jsPDF from 'jspdf';
import latinize from 'latinize';
import { Modal } from 'antd';
import { parsePhoneNumber } from 'awesome-phonenumber';

export const config = {
  env: 'staging',
  api: {
    live: 'https://chr-api.tomati.app',
    local: 'http://tomati.local',
    staging: 'https://cr-api.tomati.app',
  },
  io: {
    live: 'https://io.tomati.app',
    local: 'http://127.0.0.1:8000',
    staging: 'https://staging-io.tomati.app',
  },
}

export const app = {
  version: require('../../package.json').version,
  dbpref: 'tom_desk_',
}

export function replaceNulls(data) {
  return JSON.parse(JSON.stringify(data).replace(/:null/gi, ':""'));
  // return JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':'''));
}

export function getVariantColorByStatus(value) {
  switch (value) {
    case 'Draft':
      return 'warning';
    case 'Pending':
      return 'info';
    case 'Approved':
      return 'success';
    case 'Denied':
      return 'danger';
    default:
      return 'primary';
  }
}

export function getImgFromUrl(outlet, callback) {
  var img = new Image();
  img.src = outlet.menu_link;
  img.onload = function () {
    let formattedName = latinize(outlet.name);
    formattedName = formattedName.toLowerCase().trim().replace(/\s+/g, '');
    callback(img, outlet.name, formattedName);
  };
}

export function generatePDF(img, name, formattedName) {
  var options = { orientation: 'p', unit: 'mm' };
  var doc = new jsPDF(options);

  const pdfWidth = doc.internal.pageSize.getWidth();
  const main_header = 'SCAN CODE OR VISIT LINK BELOW';
  const menu_link = `menu.tomati.app/${formattedName}`;
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(14);
  doc.text(main_header, pdfWidth / 2 - doc.getTextWidth(main_header) / 2, 20, {
    align: 'justify',
  });
  doc.setFontSize(14);
  doc.setTextColor(224, 71, 91);
  doc.text(menu_link, pdfWidth / 2 - doc.getTextWidth(menu_link) / 2, 28, {
    align: 'justify',
  });

  try {
    doc.addImage(`${img.src}?origin=${window.location.origin}`, 'PNG', pdfWidth / 2 - 50, 29, 100, 100);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 0);
    doc.text(name, pdfWidth / 2 - doc.getTextWidth(name) / 2, 135, { align: 'justify', });
    doc.save(`${name}.pdf`);
  } catch (err) {
    console.log(err);
    // var xhr = new XMLHttpRequest(); // use sync to avoid popup blocker
    // xhr.open('HEAD', img.src, true);
    // xhr.send();
  }
}

// :: storage
export const setStorage = (key, value) => {
  if (key) {
    localStorage.setItem(app.dbpref + key, value);
  }
}
export const getStorage = (key) => {
  const value = localStorage.getItem(app.dbpref + key);
  return value || '';
}
export const setStorageJson = (key, value) => {
  if (key && value) {
    localStorage.setItem(app.dbpref + key, JSON.stringify(value));
  }
}
export const getStorageJson = (key) => {
  if (key) {
    const value = localStorage.getItem(app.dbpref + key);
    return JSON.parse(value) || '';
  }
}
export const delStorage = (key) => {
  if (key) {
    localStorage.removeItem(app.dbpref + key);
  }
}

export const ucWords = (string) => {
  if (!string) {
    return '';
  }
  string = string.trim();
  return string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
}

export const numberFormat = (number, decimal = 0) => {
  // eslint-disable-next-line no-useless-escape
  number = (number || 0).toString().replace(/[^0-9\./]/g, '');
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal }).format(number);
}

export const seoUrl = (str) => {
  return str.split(' ').join('-').replace(/[^a-zA-Z 0-9]+/gi, '-').toLowerCase();
}

export const shuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export const chunk = (inputArray, chunkSize) => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
}

export const CSVFormatter = (cd, i) => {
  var row = cd;
  var errors = [];
  var categories = getStorageJson('categories') || [];

  delete row[''];
  row = {
    ...row,
    name: ucWords(row.name).trim(),
    price: +(row.price || '').replace(',', ''),
    description: (row.description || '').trim(),
    free_sides_max: +(row.free_sides_max || 0),
    venue_categories: (row.venue_categories || '')
      .split(',')
      .map((name) => {
        return ucWords(name).trim();
      })
      .filter((item) => item),
    admin_categories: (row.admin_categories || '')
      .split(',')
      .map((name) => {
        return ucWords(name).trim();
      })
      .filter((item) => item),
    tags: (row.tags || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
    images: (row.images || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
    drinks: (row.drinks || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
    cuisines: (row.cuisines || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
    free_sides: (row.free_sides || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
    paid_sides: (row.paid_sides || '')
      .split(',')
      .map((item) => {
        return ucWords(item || '')
          .trim()
          .replace(/^[ ]+/g, '');
      })
      .filter((item) => item),
  };

  var productCategory = categories
    .map((ctg) => {
      return ctg.name.toLowerCase();
    })
    .includes((row.admin_categories[0] || '').toLowerCase());
  if (row.admin_categories.length === 0) {
    errors = errors.concat([
      `Line ${i + 2}: admin_categories can not be empty`,
    ]);
  } else if (!productCategory) {
    row['status'] = 'draft';
    errors = errors.concat([
      `Line ${i + 2}: ${row.admin_categories
      } is an invalid value for admin_categories`,
    ]);
  }
  return { row, errors };
}

export const prepTime = (order) => {
  const prepTime = Math.max(...(order.items || []).map(item => +item.preparation_time.split(':')[1]));
  if (prepTime === 0) {
    return 'Ready immediately';
  }
  return `Ready in ${`${prepTime + 1} minutes`}`;
}

export const cartTotal = (items) => {
  let total = 0;
  items.forEach((item) => {
    let price = item.price;
    Object.keys(item.variants).forEach((po) => {
      if (item.variants[po].price > 0) {
        price = item.variants[po].price;
      }
    });
    let paidPrice =
      (item.paid_sides || []).length &&
      item.paid_sides.reduce(function (sum, ps) {
        return sum + ps.price;
      }, 0);
    total = total + (price + paidPrice) * (item.quantity || 1);
  });
  return total;
}

export const loading = (content) => {
  return new Promise((resolve) => {
    content = (
      <div
        className='text-center'
        dangerouslySetInnerHTML={{
          __html: `<i class='fa fa-spin fa-spinner'></i> <span>${content}</span>`,
        }}
      />
    );
    const loading = Modal.info({
      icon: null,
      title: null,
      centered: true,
      content,
      width: '250px',
      className: 'loading-modal',
    });
    resolve(loading);
  });
}

export const alert = (title, content, props, onOK) => {
  Modal.confirm({
    icon: null,
    title,
    centered: true,
    content,
    okText: 'Okay!',
    width: '300px',
    cancelText: <div />,
    cancelButtonProps: { style: { display: 'none' } },
    ...props,
    onOk: (close) => {
      close();
      onOK && onOK();
    },
  });
}

export const distanceMatrix = (
  pointA = {},
  pointB = {},
  onlyNumber = false
) => {
  var lat1 = pointA.lat;
  var lng1 = pointA.lng;
  var lat2 = pointB.lat;
  var lng2 = pointB.lng;
  if ((lat1 === lat2 && lng1 === lng2) || !lat1 || !lat2 || !lng1 || !lng2) {
    return onlyNumber ? 0 : '--km away';
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lng1 - lng2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (((dist * 180) / Math.PI) * 60 * 1.1515 * 1.609344).toFixed(1);
    return onlyNumber ? dist : `${dist}km away`;
  }
}

export const capitalize = (word) => {
  const capitalizeWord = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalizeWord;
}

// export const useSubscription = typeof process.env.REACT_APP_USE_SUBSCRIPTION !== 'undefined' ? parseBool(process.env.REACT_APP_USE_SUBSCRIPTION) : true;
export const useSubscription = false;

export const playNotificationSound = (time_in_milisec = 2000, attr = { muted: false, loop: true }) => {
  // let beep = new Audio(audio);
  // beep.loop = true;
  // beep.play();
  // setTimeout(() => beep.pause(), time_in_milisec);
  return new Promise((resolve) => {
    let beep = document.getElementById('playNotificationAudio');
    beep.loop = attr.loop;
    beep.muted = attr.muted;
    beep.play().then(() => {
      setTimeout(() => beep.pause(), time_in_milisec);
      resolve(true);
    }).catch(e => {
      resolve(false);
    });
  });
}

export const hasAccessTo = (permission = '') => {
  // 1:admin. 2:Manager. 3:Cashier
  const role = getStorageJson('user').role?.rank || 0;
  const allPermissions = getStorageJson('permissions') || {};
  return !!(role === 6 || (allPermissions[permission] || []).includes(role));
}


export const addressComponents = (plc) => {
  const addressObject = {
    lat: typeof plc.geometry.location.lat === 'number' ? plc.geometry.location.lat : plc.geometry.location.lat(),
    lng: typeof plc.geometry.location.lng === 'number' ? plc.geometry.location.lng : plc.geometry.location.lng(),
    iso: (((plc.address_components || []).find((pa) => (pa.types || []).includes('country')) || { short_name: '' }).short_name || '').toLowerCase(),
    city: ((plc.address_components || []).find((pa) => (pa.types || []).includes('locality')) || { long_name: '' }).long_name,
    name: `${plc.name ? `${plc.name}, ` : ''}${plc.formatted_address.replace(`${plc.name}, `, ``)}`,
    state: ((plc.address_components || []).find((pa) => (pa.types || []).includes('administrative_area_level_1')) || { long_name: '' }).long_name,
    street: ((plc.address_components || []).find((pa) => (pa.types || []).includes('route')) || {}).long_name || '',
    country: ((plc.address_components || []).find((pa) => (pa.types || []).includes('country')) || { long_name: '' }).long_name,
    house_no: ((plc.address_components || []).find((pa) => (pa.types || []).includes('street_number')) || {}).long_name || '',
  };
  return addressObject;
}

export const decodePolyline = (encoded) => {
  if (!encoded) {
    return [];
  }
  var poly = [];
  var index = 0, len = encoded.length;
  var lat = 0, lng = 0;

  while (index < len) {
    var b, shift = 0, result = 0;

    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);

    var dlat = (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);
    lat += dlat;

    shift = 0;
    result = 0;

    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);

    var dlng = (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);
    lng += dlng;

    var p = {
      latitude: lat / 1e5,
      longitude: lng / 1e5,
    };
    poly.push(p);
  }
  return poly;
}

export const findItem = (items, code) => items
  .find(item => item.code === code) ||
  items
    .map(item => item.variants.map(iv => iv.options).flat()).flat()
    .map(item => {
      return {
        code: item.code,
        name: item.name || item.option,
      };
    })
    .find(item => item.code === code);


export const sortString = (a, b, key) => {
  const fa = a[key].toLowerCase();
  const fb = b[key].toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
}

export const format = {
  phoneNumber: (a) => parsePhoneNumber(a).number?.national || a,
}

export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const versionBreak = (v) => {
  return v ? Number(v.split('.').join('')) : 0;
}


export const imgError = (e) => {
  e.target.src = 'https://chr-assets.tomati.app/venues/chicken-republic-logo.png';
}

export const requiredInput = { required: true, message: 'This field is required' };

export const isLocal = window.location.host.includes('localhost');
export const isStaging = window.location.host.includes('cr-desktop');
export const isStagingOrLocal = isLocal || isStaging;

export const deliveryFee = (channel, source, destination) => {
  const location = getStorageJson('location') || {};
  const active_venue = getStorageJson('active_venue') || {};
  const delivery_partners = getStorageJson('delivery_partners') || [];

  const distance = +distanceMatrix(
    { lat: destination.lat, lng: destination.lng },
    { lat: source.lat, lng: source.lng, },
    true
  );

  if (channel) {
    switch (channel) {
      case 'cr':
        return active_venue?.delivery?.fee_type === 'flat' ? active_venue?.delivery?.fee : active_venue?.delivery?.fee * distance;

      default:
        const deliveryPartner = delivery_partners[channel] || {};
        const deliveryFee = (Object.keys(deliveryPartner[location.address.state.toLowerCase()]) || [])
          .find((dist) => +dist.split('-')[0] <= distance && distance <= +dist.split('-')[1]);
        return deliveryFee ? deliveryPartner[location.address.state.toLowerCase()][deliveryFee] : 0;
    }
  }
  return 0;
}

export const redirect = (to) => window.location = to;



(function () {
  if (window.location.hostname === 'localhost') {
    config['env'] = 'local';
  } else if (window.location.host.includes('cr-desktop')) {
    config['env'] = 'staging';
  } else {
    config['env'] = 'live';
  }
})();