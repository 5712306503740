import React from 'react';
import { helper } from 'utils';

const NotificationAudio = ({ open }) => {

    const [played, setPlayed] = React.useState('none');

    React.useEffect(() => {
        setPlayed('none');
        if (open) {
            setPlayed('flex');
        }
    }, [open]);

    const close = () => {
        helper.playNotificationSound(9000, { muted: true, loop: false }).then(e => {
            setPlayed('none');
        });
    }

    return (
        <React.Fragment>
            <div id="NotificationAudio" style={{ display: played }}>
                <div>
                    <h4 className="primary">Get Notified!</h4>
                    <div className="text-muted mt-2 mb-3">To activate notification sound on new orders, <br /> please play this sound.</div>
                    <audio id="playNotificationAudio" controls style={{ display: 'none' }}>
                        <source src="./io_orchestral_emergency.wav" type="audio/mpeg" />
                    </audio>
                    <div>&nbsp;</div>
                    <button className="btn btn-primary" onClick={close}>
                        &nbsp; &nbsp; &nbsp; Enable &nbsp; &nbsp; &nbsp;
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationAudio;