import axios from 'axios';
import 'utils/axios_configuration';
import * as APIRoutes from 'constants/APIRoutes';
import { helper, axius } from 'utils';
// import 
// TODO: axios default configurations
class AuthAPI {

  static async postLoginDetails(postData) {
    try {
      // fetch data from a url endpoint
      const response = await axios.post(APIRoutes.CLIENT_LOGIN, postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async getUserData() {
    const logg = helper.getStorageJson('userData') || {};
    // fetch data from a url endpoint
    if (logg.uuid) {
      let res = await axius.get(`users/${logg.uuid}`);
      if (res.status === 200) {
        const data = { ...res.data };
        data.venues = data.venues.sort((a, b) => a.name.localeCompare(b.name));
        // const roles = [
        //   { 'id': 1, 'rank': 1, 'name': 'Admin' },
        //   { 'id': 2, 'rank': 2, 'name': 'Regional Manager' },
        //   { 'id': 4, 'rank': 3, 'name': 'Area Manager' },
        //   { 'id': 5, 'rank': 4, 'name': 'Store Manager' },
        //   { 'id': 3, 'rank': 5, 'name': 'Cashier' },
        // ];
        // data['role'] = roles.find(r => r.rank === 5);
        helper.setStorageJson('user', data);
        helper.setStorageJson('userData', data);
        return data;
      } else {
        if (logg.uuid) {
          localStorage.clear();
          sessionStorage.clear();
          helper.setStorage('expired', 'yah');
          window.location.replace('/');
        }
      }
    }
    return;
  }

  static async updateUser(data) {
    const logg = helper.getStorageJson('userData');
    const res = await axius.put(`users/${logg.uuid}`, data);
    return res.data;
  }

  static async verifyCredentails(postData) {
    const data = { email: postData };
    try {
      // fetch data from a url endpoint
      const response = await axios.post(APIRoutes.VERIFY_CREDENTIALS, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async checkCode(postData) {
    try {
      // fetch data from a url endpoint
      const response = await axios.post(APIRoutes.CHECK_EMAIL_CODE, postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async getEmailOtp(postData) {
    try {
      // fetch data from a url endpoint
      const response = await axios.post(APIRoutes.GET_EMAIL_OTP, postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async collaboratorSignup(data) {
    try {
      let URL;
      if (data.outlet_event) {
        URL = APIRoutes.INVITE_COLLABORATOR_EVENT(data.outlet_event);
      } else if (data.outlet_venue) {
        URL = APIRoutes.INVITE_COLLABORATOR_VENUE(data.outlet_venue);
      }
      const response = await axios.post(URL, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async getUsers() {
    try {
      // fetch data from a url endpoint
      const response = await axios.get(APIRoutes.GET_ALL_USERS);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async getUserLimits(data) {
    try {
      // fetch data from a url endpoint
      const response = await axios.post(APIRoutes.GET_USER_LIMIT, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
export default AuthAPI;
