
import axios from 'axios';
import { helper } from 'utils';

const signOutSuccess = (path = '/') => {
    helper.delStorage('token');
    helper.delStorage('user');
    helper.redirect(path);
}

export const apnData = (obj) => {
    const body = new FormData();
    for (let p in obj) {
        if (Array.isArray(obj[p])) {
            for (let i = 0; i < obj[p].length; i++) {
                body.append(`${p}[${i}]`, obj[p][i]);
            }
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (type = '') => {
    var token = helper.getStorage('token') || '';
    if (type === 'file') {
        return {
            'Accept': 'application/json',
            'x-access-token': `${token}`,
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': `${token}`,
        };
    }
}

export const get = async (endpoint, data = {}) => {
    let headers = apiHeaders();
    return axios({
        method: 'GET', url: helper.config.api[helper.config.env] + '/' + endpoint,
        headers,
        params: data
    }).then(response => {
        return respunse(response.data);
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const post = async (endpoint, data = {}) => {
    let headers = apiHeaders();
    try {
        let response = await fetch(helper.config.api[helper.config.env] + '/' + endpoint, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const put = async (endpoint, data = {}) => {
    let headers = apiHeaders();
    try {
        let response = await fetch(helper.config.api[helper.config.env] + '/' + endpoint, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const delte = async (endpoint, data = {}) => {
    let headers = apiHeaders();
    try {
        let response = await fetch(helper.config.api[helper.config.env] + '/' + endpoint, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const postFile = async (endpoint, data = {}) => {
    let headers = apiHeaders('file');
    return await fetch(helper.config.api[helper.config.env] + '/' + endpoint, {
        method: 'POST',
        headers,
        body: apnData(data)
    }).then(async (response) => {
        return respunse(await response.json());
    }).catch(error => {
        return { status: 606, message: 'Network request failed', error };
    });
}

const respunse = (res) => {
    if ([505, 401].includes(res.status) && (helper.getStorageJson('user') || {}).uuid) {
        return signOutSuccess();
    } else if (res.token) {
        helper.setStorage('token', res.token);
    }
    return res;
}